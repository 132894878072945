import { createI18n } from "vue-i18n";

const localeModules = require.context("./locales", true, /.\/.*\.*/);

const messages = {};

localeModules.keys().forEach((fileName) => {
  const fileNameParts = fileName.split("/");
  const fileNameWithoutPath = fileNameParts[fileNameParts.length - 1];
  const localeName = fileNameWithoutPath.split(".json")[0];

  messages[localeName] = localeModules(fileName);
});

export default createI18n({
  legacy: false,
  locale: "br",
  fallbackLocale: "br",
  messages,
});
